var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "email_verification-card",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          _vm.loading ? _c("v-card-loader") : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "gap-xs-3": "",
                    "mb-1": ""
                  }
                },
                [
                  _c("v-flex", { attrs: { xs12: "", sm11: "", md10: "" } }, [
                    _c("h1", { staticClass: "starling-h1" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$t("public.signup.verify.start.title"))
                      )
                    ])
                  ]),
                  _vm._l(_vm.$t("public.signup.verify.start.content"), function(
                    ignored,
                    index
                  ) {
                    return _c(
                      "v-flex",
                      { key: index, attrs: { xs12: "", sm11: "", md10: "" } },
                      [
                        _c("p", {
                          staticClass: "starling-body",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t(
                                "public.signup.verify.start.content[" +
                                  index +
                                  "]",
                                {
                                  email:
                                    _vm.$store.state.auth.email_verification
                                      .username
                                }
                              )
                            )
                          }
                        })
                      ]
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }