<template>
  <landing-central-card>
    <v-card flat class="email_verification-card" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="loading"></v-card-loader>

      <v-card-text class="pa-0">
        <v-layout row wrap justify-center gap-xs-3 mb-1>
          <v-flex xs12 sm11 md10>
            <h1 class="starling-h1"> {{ $t('public.signup.verify.start.title') }}</h1>
          </v-flex>

          <v-flex xs12 sm11 md10 v-for="(ignored ,index) in $t('public.signup.verify.start.content')" :key="index">
            <p class="starling-body" v-html="$t('public.signup.verify.start.content['+index+']', {email:$store.state.auth.email_verification.username})"></p>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </landing-central-card>
</template>

<script>
import vCardLoader from '@/views/components/loader/v-card-loader.vue';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import { mapState } from 'vuex';

export default {
  components: {
    LandingCentralCard,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      loading: false,
      code: null,
      error: null,
      success: null,
      tries: 0,
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
  },
};
</script>

<style scoped lang="scss">
.email_verification-card {
  border-radius: 15px;
}
</style>
